<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

export default {
  name: "ForgotPassword",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <section
      class="section section-lg height-100vh"
      id="home"
      :style="{
        'background-image':
          'url(' + require('@/assets/images/404/3747371.jpg') + ')',
        'background-size': 'cover',
        'background-position': 'center',
      }"
    >
      <div class="bg-overlay"></div>
      <div class="display-table">
        <div class="home-cell-bottom">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2 text-white text-center">
                <h1 class="home-title">ÜZGÜNÜZ ARADIĞINIZ SAYFA BULUNAMADI</h1>
                <p class="padding-t-15 home-desc">
                  Sayfa kaldırılmış veya adresi değiştirilmiş olabilir.
                </p>
                <p class="padding-t-15 home-desc">
                  Adresi kontrol ederek tekrar deneyebilir ya da anasayfaya
                  gitmek için aşağıdaki butonu kullanabilirsiniz.
                </p>
                <a
                  href="/"
                  class="btn btn-custom margin-t-30 waves-effect waves-light"
                >
                  Anasayfa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>
